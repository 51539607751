<template>
    <ion-page>
        <ion-header>
            <div class="container-toolbar bg-main">
                <ion-toolbar class="bg-main">
                    <ion-buttons slot="start">
                        <ion-button @click="closeModal"><ion-icon :icon="close"></ion-icon></ion-button>
                    </ion-buttons>
                    <ion-title>{{ title }}</ion-title>
                </ion-toolbar>
            </div>
        </ion-header>

        <ion-content>
            <div v-show="showAutocomplete">
                <input type="text" v-model="autocompleteText" placeholder="Escriu la direcció... (Ex. Carrer Manresa 14, Vic (carrer, número i població))" ref="gmapsautocomplete" class="input-autocomplete" />
            </div>
            <div v-show="!showAutocomplete" class="address-form">
                <div class="form-container">
                    <div class="address-row">
                        <b>DIRECCIÓ SELECCIONADA <button class="btn-change" @click="showAutocomplete = true; autocompleteText = ''">Canviar</button></b>
                        <div>{{ addressTitle }}</div>
                    </div>
                    <div class="desc">Modifica l'adreça si falten dades (com pis, porta, etc)</div>
                    <div>
                        <input v-model="addressParts.street" placeholder="Carrer">
                    </div>
                    <div>
                        <input v-model="addressParts.number" placeholder="Número">
                        <input v-model="addressParts.flat" placeholder="Pis">
                        <input v-model="addressParts.door" placeholder="Porta">
                    </div>
                    <div>
                        <input v-model="addressParts.city" readonly placeholder="Ciutat">
                    </div>
                    <ion-button expand="full" @click="setAddress">Establir com a {{ title }}</ion-button>
                </div>
                <div class="map-container" id="map"></div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar,
    alertController, IonButtons, IonIcon, IonButton
} from '@ionic/vue';
import {defineComponent} from 'vue';
import gmapsInit from '@/utils/gmaps';
import {AddressRepository} from "@/repositories/address-repository";
import {authStore} from "@/stores/auth-store";
import {mapState} from "pinia";
import {modalController} from "@ionic/vue";
import {AlertsUtils} from "@/utils/alerts-utils";
import { close } from 'ionicons/icons';

const addressRepository = new AddressRepository();

export default defineComponent({
    name: 'NewAddressModal',
    data() {
        return {
            close,
            autocompleteText: '',
            showAutocomplete: true,
            google: null as any,
            addressTitle: '',
            addressParts: {
                street: '',
                number: '',
                flat: '',
                door: '',
                city: '',
                postalCode: '',
                latitude: '',
                longitude: '',
            }
        }
    },
    async mounted() {
        try {
            this.google = await gmapsInit();
            const autocomplete = new this.google.maps.places.Autocomplete(this.$refs['gmapsautocomplete'], { types: ['address'] });

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                this.handlePlaceSelect(place);
            });

            setTimeout(() => {
                // @ts-ignore
                this.$refs.gmapsautocomplete.focus();
            }, 1000)

        } catch (error) {
            console.error(error);
        }
    },
    methods: {
        setAddress() {
            if ( this.validateAddress() ) {
                addressRepository.createAddress(this.user.id, this.addressParts.latitude, this.addressParts.longitude, this.buildAddress()).then( resp => {
                    modalController.dismiss({
                        "latitude": resp.data.latitude,
                        "longitude": resp.data.longitude,
                        "lat": resp.data.latitude,
                        "lng": resp.data.longitude,
                        "address": resp.data.address,
                        "house": resp.data.house,
                        "tag":null
                    });
                });
            }
        },
        buildAddress(): string {
            let address = '';
            address = this.addressParts.street + ', ' + this.addressParts.number;
            if ( this.addressParts.flat !== '' ) {
                address += ', Pis ' + this.addressParts.flat;
            }
            if ( this.addressParts.door !== '' ) {
                address += ', Porta ' + this.addressParts.door;
            }
            address += ', ' + this.addressParts.city + ' (' + this.addressParts.postalCode + ')';
            return address;
        },
        validateAddress(): boolean {
            if ( this.addressParts.street.length === 0 ) {
                AlertsUtils.showSimpleAlert("Has d'introduir el nom del carrer");
                return false;
            }
            if ( this.addressParts.number === '' ) {
                AlertsUtils.showSimpleAlert("Has d'introduir el número");
                return false;
            }
            return true;
        },
        handlePlaceSelect(place: any) {
            this.showAutocomplete = false;
            this.addressTitle = place.formatted_address;
            this.fillInAddress(place);
            this.drawMap(place.geometry.location.lat(), place.geometry.location.lng());
        },
        closeModal() {
            modalController.dismiss();
        },
        fillInAddress(place: any) {
            let postcode = "";

            this.addressParts.latitude = place.geometry.location.lat();
            this.addressParts.longitude = place.geometry.location.lng();

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            for (const component of place.address_components) {
                // @ts-ignore remove once typings fixed
                const componentType = component.types[0];

                switch (componentType) {
                    case "street_number": {
                        this.addressParts.number = `${component.long_name}`;
                        break;
                    }

                    case "route": {
                        this.addressParts.street = component.short_name;
                        break;
                    }

                    case "postal_code": {
                        this.addressParts.postalCode = `${component.long_name}${postcode}`;
                        break;
                    }

                    case "postal_code_suffix": {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }

                    case "locality":
                        this.addressParts.city = component.long_name;
                        break;

                    case "administrative_area_level_1": {
                        // this.addressParts. =
                        //     component.short_name;
                        break;
                    }

                    case "country":
                        // (document.querySelector("#country") as HTMLInputElement).value =
                        //     component.long_name;
                        break;
                }
            }
        },
        drawMap(lat: number, lng: number) {
            const center = new this.google.maps.LatLng(lat, lng);
            const map = new this.google.maps.Map(document.getElementById('map'), {
                center,
                zoom: 14,
                streetViewControl: false,
            })
            new this.google.maps.Marker({
                map: map,
                position: center
            })
        }
    },
    computed: {
        ...mapState(authStore, ['user']),
    },
    props: {
        title: {
            type: String,
            default: "Establir direcció"
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        IonButton,
    }
});
</script>

<style lang="scss" scoped>
.input-autocomplete {
    width: 100%;
    font-size: 17px;
    padding: 13px 15px;
    border: none;
    border-bottom: 1px solid #d6d6d6;
    outline: none;
}
.address-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    .map-container {
        flex: 30%;
    }
    .form-container {
        flex: 70%;
        padding: 15px;
        max-height: 300px;
        >div {
            display: flex;
            margin: 2px;
        }
        input {
            width: 100%;
            border: 1px solid #bbbbbb;
            font-size: 17px;
            padding: 8px 12px;
            outline: none;
            margin: 2px 3px;
        }
    }
    .address-row {
        flex-direction: column;
        border-bottom: 1px solid #d0d0d0;
        padding-bottom: 8px;
        margin-bottom: 11px !important;
        b {
            margin-bottom: 4px;
        }
    }
    .desc {
        color: gray;
        font-size: 13px;
        padding-bottom: 5px;
        padding-left: 3px;
    }
    .btn-change {
        padding: 2px 8px;
        margin-left: 6px;
        background: white;
        color: #99be13;
        border: 1px solid #99be13;
    }
    ion-button {
        margin-top: 10px;
    }
}
</style>
