
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar, IonButtons, IonButton, IonIcon,
    loadingController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {modalController} from "@ionic/vue";
import {StoreRepository} from "@/repositories/store-repository";
import moment from 'moment'
import { close } from 'ionicons/icons';

const storeRepository = new StoreRepository();

export default defineComponent({
    name: 'DateSelectModal',
    data() {
        return {
            close,
            timeslots: [] as any[],
            selectedDay: null as any,
            selectedDate: null as any
        }
    },
    async mounted() {
        moment.locale('ca');
        this.getStoreTimeslots();
    },
    methods: {
        async getStoreTimeslots() {
            const loading = await loadingController.create({})
            await loading.present();
            storeRepository.getStoreInfo(this.storeId).then(resp => {
                this.timeslots = resp.data.timeslots;
                if (Object.keys(this.timeslots).length > 0) {
                    this.setDay(Object.keys(this.timeslots)[0]);
                }
            }).finally( () => {
                loading.dismiss();
            })
        },
        setDay(day: string) {
            this.selectedDay = day;
        },
        selectDate(time: any) {
            modalController.dismiss(this.selectedDay + ' ' + time);
        },
        closeModal() {
            modalController.dismiss();
        },
        dateDayName(date: string) {
            return moment(date).format('dd');
        },
        dateDayNum(date: string) {
            return moment(date).format('D');
        }
    },
    props: {
        storeId: {
            type: Number,
            required: true
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        IonButton,
    },
});
