import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import mitt from 'mitt'

// Added by the CLI
import './registerServiceWorker'

import {createPinia} from 'pinia'
import interceptorsSetup from './interceptors/base-interceptors'

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $emitter: any;
    }
}


// Interceptors setup
interceptorsSetup();

const app = createApp(App)
    .use(IonicVue, {
        mode: 'ios',
        backButtonText: '',
    })
    .use(router);

app.use(createPinia())

app.config.globalProperties.$emitter = mitt();

router.isReady().then(() => {
    app.mount('#app');
});
