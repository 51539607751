<template>
    <ion-content>
        <ion-list>
            <ion-item>
                <ion-label class="grey">{{ user.email }}</ion-label>
            </ion-item>
            <ion-item @click="doLogout" class="clickable">
                <ion-icon :icon="logOutOutline" slot="start"></ion-icon>
                <ion-label>Tancar sessió</ion-label>
            </ion-item>
        </ion-list>
    </ion-content>
</template>

<script>
import {IonContent, IonLabel, IonItem, IonList, IonIcon, popoverController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {logOutOutline} from 'ionicons/icons';
import {authStore} from "@/stores/auth-store";
import {mapActions, mapState} from "pinia";

export default defineComponent({
    name: 'UserPopover',
    data() {
        return {
            logOutOutline,
        }
    },
    methods: {
        doLogout() {
            this.logout();
            popoverController.dismiss();
            this.$router.replace('/login');
        },
        ...mapActions(authStore, ['logout'])
    },
    computed: {
        ...mapState(authStore, ['user'])
    },
    components: {IonContent, IonList, IonItem, IonLabel, IonIcon}
});
</script>

<style lang="scss">
.grey {
    --color: grey !important;
}
</style>
