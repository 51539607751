import axios from 'axios';
import {authStore} from "@/stores/auth-store";

export default function setup() {

    axios.interceptors.request.use(
        config => {
            const token = authStore().token;
            if (token) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            if (response.status === 200 || response.status === 201) {
                return Promise.resolve(response);
            } else {
                return Promise.reject(response);
            }
        },
        error => {
            if (error.response.status) {
                switch (error.response.status) {
                    case 400:
                        break;
                    case 401:
                        break;
                    case 403:
                        break;
                    case 404:
                        break;
                    case 502:
                        break;
                }
                return Promise.reject(error.response);
            }
        }
    );

}
