<template>
    <ion-page>
        <ion-header>
            <div class="container-toolbar bg-main">
                <ion-toolbar class="bg-main">
                    <ion-buttons slot="start">
                        <ion-back-button></ion-back-button>
                    </ion-buttons>
                    <ion-title>Nou enviament</ion-title>
                </ion-toolbar>
            </div>
        </ion-header>

        <ion-content :fullscreen="true">

            <div class="container container-desktop">
                <div class="section">
                    <div class="section-title">DESCRIPCIÓ DEL PRODUCTE</div>
                    <ion-item>
                        <ion-icon :icon="cubeOutline" slot="start"></ion-icon>
                        <ion-textarea v-model="form.comments" placeholder="Descriu el/s productes a enviar"></ion-textarea>
                    </ion-item>
                </div>
                <div class="section">
                    <div class="section-title">PUNT DE RECOLLIDA</div>
                    <ion-item class="clickable" @click="openStoreSelect" detail>
                        <ion-icon :icon="locationOutline" slot="start"></ion-icon>
                        <div>
                            <div class="text-placeholder" v-if="form.store === null">A on ho hem de recollir?</div>
                            <div v-if="form.store !== null"><i>{{ form.store.name }}</i></div>
                            <div v-if="form.store !== null">{{ form.store.address }}</div>
                        </div>
                    </ion-item>
                </div>
                <div class="section">
                    <div class="section-title">DIRECCIÓ D'ENTREGA</div>
                    <ion-item class="clickable" @click="openClientAddressPickModal" detail>
                        <ion-icon :icon="flagOutline" slot="start"></ion-icon>
                        <div>
                            <div class="text-placeholder" v-if="form.clientAddress === null">A on vols que ho entreguem?</div>
                            <div v-if="form.clientAddress" :class="{'has-error': !canDeliver}">{{ form.clientAddress.address }}</div>
                            <div class="has-error error-delivery" v-if="!canDeliver">No entreguem a aquesta direcció</div>
                        </div>
                    </ion-item>
                </div>
                <div class="section">
                    <div class="section-title">HORA D'ENTREGA</div>
                    <ion-item class="clickable" @click="openDateSelectModal" detail>
                        <ion-icon :icon="timeOutline" slot="start"></ion-icon>
                        <div>
                            <div class="text-placeholder" v-if="form.date === null">Quan ho vols entregar?</div>
                            <div class="capitalize" v-if="form.date !== null">{{ dateFormatted() }}</div>
                        </div>
                    </ion-item>
                </div>
                <div class="section">
                    <div class="section-title">TELÈFON CONTACTE CLIENT</div>
                    <ion-item>
                        <ion-icon :icon="phonePortraitOutline" slot="start"></ion-icon>
                        <ion-input v-model="form.phone" placeholder="El número de telèfon del client"></ion-input>
                    </ion-item>
                </div>
                <div class="delivery-cost" v-if="deliveryCost">
                    <div>Cost d'entrega</div>
                    <div class="price">{{ deliveryCostFormatted() }}€</div>
                </div>
                <ion-button @click="placeOrder" expand="full" :disabled="!canDeliver">Confirmar enviament</ion-button>
            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonTextarea,
    IonTitle,
    IonItem,
    IonPage,
    IonToolbar,
    modalController,
    actionSheetController,
    loadingController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import { cubeOutline, locationOutline, flagOutline, phonePortraitOutline, timeOutline } from 'ionicons/icons';
import NewAddressModal from "@/views/modals/NewAddressModal.vue";
import {UserRepository} from "@/repositories/user-repository";
import DateSelectModal from "@/views/modals/DateSelectModal.vue";
import {AlertsUtils} from "@/utils/alerts-utils";
import moment from 'moment'
import {StoreRepository} from "@/repositories/store-repository";
import {OrdersRepository} from "@/repositories/orders-repository";

const userRepository = new UserRepository();
const storeRepository = new StoreRepository();
const ordersRepository = new OrdersRepository();
import uniqid from 'uniqid';
import PaymentModal from "@/views/modals/PaymentModal.vue";
import {mapGetters, mapState} from "pinia";
import {authStore} from "@/stores/auth-store";
import Swal from 'sweetalert2'

export default defineComponent({
    name: 'NewOrder',
    data() {
        return {
            cubeOutline,
            locationOutline,
            flagOutline,
            phonePortraitOutline,
            timeOutline,
            form: {
                comments: '',
                clientAddress: null as any,
                date: null as any,
                store: null as any,
                phone: null as any,
                uuid: null as any
            },
            stores: [] as any[],
            canDeliver: true,
            deliveryCost: null as any
        }
    },
    mounted() {
        moment.locale('ca');
        this.getStores();
        this.generateUuid();
    },
    methods: {
        generateUuid() {
            this.form.uuid = uniqid.time( this.user.id ).toUpperCase();
        },
        async placeOrder() {

            if ( this.validateOrder() ) {
                const loading = await loadingController.create({})
                await loading.present()
                ordersRepository.placeOrder(
                    this.form.comments,
                    this.form.store.id,
                    this.form.date,
                    this.form.clientAddress,
                    this.form.phone,
                    this.form.uuid
                ).then( () => {
                    Swal.fire({
                        title: 'Comanda realitzada correctament',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: "Anar a les meves comandes"
                    }).then( () => {
                        this.$router.replace('/home');
                        this.$emitter.emit('refreshHome');
                    })
                }).catch(err => {
                    if ( err.status !== undefined && err.status === 402 ) {
                        this.openPaymentModal(this.form.uuid);
                    } else {
                        this.showErrorAndGoBack(err.data);
                    }
                }).finally( () => {
                    loading.dismiss();
                })
            }

        },
        validateOrder() {

            if ( this.form.comments.length === 0 ) {
                AlertsUtils.showSimpleAlert("Has d'introduir la descripció del producte a enviar");
                return false;
            }

            if ( this.form.store === 0 ) {
                AlertsUtils.showSimpleAlert("Has de triar el punt de recollida");
                return false;
            }

            if ( this.form.clientAddress === null ) {
                AlertsUtils.showSimpleAlert("Has d'introduir la direcció del client");
                return false;
            }

            if ( this.form.date === null ) {
                AlertsUtils.showSimpleAlert("Has de triar el dia i hora d'entrega");
                return false;
            }

            if ( this.form.phone === null ) {
                AlertsUtils.showSimpleAlert("Has d'introduir el telèfon de contacte del client");
                return false;
            }

            return true;
        },
        async openPaymentModal(flowId: string) {
            const modal = await modalController
                .create({
                    component: PaymentModal,
                    backdropDismiss: false,
                    swipeToClose: false,
                    keyboardClose: false,
                    componentProps: {
                        flowId: flowId
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    if ( res.data === 'placeOrder' ) {
                        this.placeOrder();
                    } else {
                        this.showErrorAndGoBack(res.data);
                        this.generateUuid();
                    }
                } else {
                    this.generateUuid();
                }
            })

            return modal.present();
        },
        showErrorAndGoBack(message: string) {
            Swal.fire({
                title: 'Error al realitzar la comanda',
                text: message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: "D'acord"
            });
        },
        async openClientAddressPickModal() {
            const modal = await modalController
                .create({
                    component: NewAddressModal,
                    componentProps: {
                        title: "Direcció d'entrega"
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    this.form.clientAddress = res.data;
                    this.checkCanDelivery();
                }
            })

            return modal.present();
        },
        async openDateSelectModal() {
            if ( this.form.store === null ) {
                AlertsUtils.showSimpleAlert("Selecciona un punt de recollida primer");
                return;
            }

            const modal = await modalController
                .create({
                    component: DateSelectModal,
                    componentProps: {
                        storeId: this.form.store.id
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    this.form.date = res.data;
                }
            })

            return modal.present();
        },
        getStores() {
            userRepository.getStores().then( resp => {
                this.stores = resp.data;
                if ( this.stores.length === 1 ) {
                    this.form.store = this.stores[0];
                }
            })
        },
        checkCanDelivery() {
            if ( this.form.store === null || this.form.clientAddress === null ) return;

            storeRepository.checkStoreOperatesAtLocation( this.form.store.id, this.form.clientAddress.lat, this.form.clientAddress.lng ).then( res => {
                this.deliveryCost = res.data.delivery_charges;
                this.canDeliver = res.data.operates;
            })
        },
        async openStoreSelect() {
            const options = [];

            for(const store of this.stores) {
                options.push({
                    text: store.name,
                    handler: () => {
                        this.form.store = store;
                        this.checkCanDelivery();
                    },
                });
            }

            const actionSheet = await actionSheetController
                .create({
                    header: 'Selecciona el punt de recollida',
                    buttons: options,
                });
            await actionSheet.present();
        },
        dateFormatted() {
            if ( this.form ) {
                return moment(this.form.date).format('dddd D, HH:mm');
            } else {
                return '';
            }
        },
        deliveryCostFormatted() {
            return this.deliveryCost ? this.deliveryCost.replace('.', ',') : null;
        }
    },
    computed: {
        ...mapState(authStore, ['user']),
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonIcon,
        IonTextarea,
        IonItem,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonInput
    }
});
</script>

<style lang="scss" scoped>
.section {
    padding: 10px 0;
    .section-title {
        padding-bottom: 2px;
        border-bottom: 1px solid #dedede;
        margin-bottom: 5px;
    }
    ion-item {
        align-items: center;
        &.item-interactive {
            cursor: pointer;
        }
    }
}
.clickable {
    cursor: pointer;
    &:hover {
        --background: #f9f9f9;
    }
}
.text-placeholder {
    color: gray;
}
.delivery-cost {
    font-size: 18px;
    margin: 24px 0;
    text-align: center;
    .price {
        font-weight: bold;
    }
}
.capitalize {
    text-transform: capitalize;
}
.has-error {
    color: #dc4747;
}
.error-delivery {
 font-weight: bold;
 margin-top: 2px;
}
</style>
