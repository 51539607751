
import {
    IonButtons,
    IonBackButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonTextarea,
    IonTitle,
    IonItem,
    IonPage,
    IonToolbar,
    modalController,
    actionSheetController,
    loadingController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import { cubeOutline, locationOutline, flagOutline, phonePortraitOutline, timeOutline } from 'ionicons/icons';
import NewAddressModal from "@/views/modals/NewAddressModal.vue";
import {UserRepository} from "@/repositories/user-repository";
import DateSelectModal from "@/views/modals/DateSelectModal.vue";
import {AlertsUtils} from "@/utils/alerts-utils";
import moment from 'moment'
import {StoreRepository} from "@/repositories/store-repository";
import {OrdersRepository} from "@/repositories/orders-repository";

const userRepository = new UserRepository();
const storeRepository = new StoreRepository();
const ordersRepository = new OrdersRepository();
import uniqid from 'uniqid';
import PaymentModal from "@/views/modals/PaymentModal.vue";
import {mapGetters, mapState} from "pinia";
import {authStore} from "@/stores/auth-store";
import Swal from 'sweetalert2'

export default defineComponent({
    name: 'NewOrder',
    data() {
        return {
            cubeOutline,
            locationOutline,
            flagOutline,
            phonePortraitOutline,
            timeOutline,
            form: {
                comments: '',
                clientAddress: null as any,
                date: null as any,
                store: null as any,
                phone: null as any,
                uuid: null as any
            },
            stores: [] as any[],
            canDeliver: true,
            deliveryCost: null as any
        }
    },
    mounted() {
        moment.locale('ca');
        this.getStores();
        this.generateUuid();
    },
    methods: {
        generateUuid() {
            this.form.uuid = uniqid.time( this.user.id ).toUpperCase();
        },
        async placeOrder() {

            if ( this.validateOrder() ) {
                const loading = await loadingController.create({})
                await loading.present()
                ordersRepository.placeOrder(
                    this.form.comments,
                    this.form.store.id,
                    this.form.date,
                    this.form.clientAddress,
                    this.form.phone,
                    this.form.uuid
                ).then( () => {
                    Swal.fire({
                        title: 'Comanda realitzada correctament',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: "Anar a les meves comandes"
                    }).then( () => {
                        this.$router.replace('/home');
                        this.$emitter.emit('refreshHome');
                    })
                }).catch(err => {
                    if ( err.status !== undefined && err.status === 402 ) {
                        this.openPaymentModal(this.form.uuid);
                    } else {
                        this.showErrorAndGoBack(err.data);
                    }
                }).finally( () => {
                    loading.dismiss();
                })
            }

        },
        validateOrder() {

            if ( this.form.comments.length === 0 ) {
                AlertsUtils.showSimpleAlert("Has d'introduir la descripció del producte a enviar");
                return false;
            }

            if ( this.form.store === 0 ) {
                AlertsUtils.showSimpleAlert("Has de triar el punt de recollida");
                return false;
            }

            if ( this.form.clientAddress === null ) {
                AlertsUtils.showSimpleAlert("Has d'introduir la direcció del client");
                return false;
            }

            if ( this.form.date === null ) {
                AlertsUtils.showSimpleAlert("Has de triar el dia i hora d'entrega");
                return false;
            }

            if ( this.form.phone === null ) {
                AlertsUtils.showSimpleAlert("Has d'introduir el telèfon de contacte del client");
                return false;
            }

            return true;
        },
        async openPaymentModal(flowId: string) {
            const modal = await modalController
                .create({
                    component: PaymentModal,
                    backdropDismiss: false,
                    swipeToClose: false,
                    keyboardClose: false,
                    componentProps: {
                        flowId: flowId
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    if ( res.data === 'placeOrder' ) {
                        this.placeOrder();
                    } else {
                        this.showErrorAndGoBack(res.data);
                        this.generateUuid();
                    }
                } else {
                    this.generateUuid();
                }
            })

            return modal.present();
        },
        showErrorAndGoBack(message: string) {
            Swal.fire({
                title: 'Error al realitzar la comanda',
                text: message,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: "D'acord"
            });
        },
        async openClientAddressPickModal() {
            const modal = await modalController
                .create({
                    component: NewAddressModal,
                    componentProps: {
                        title: "Direcció d'entrega"
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    this.form.clientAddress = res.data;
                    this.checkCanDelivery();
                }
            })

            return modal.present();
        },
        async openDateSelectModal() {
            if ( this.form.store === null ) {
                AlertsUtils.showSimpleAlert("Selecciona un punt de recollida primer");
                return;
            }

            const modal = await modalController
                .create({
                    component: DateSelectModal,
                    componentProps: {
                        storeId: this.form.store.id
                    },
                })

            modal.onDidDismiss().then( res => {
                if ( res.data ) {
                    this.form.date = res.data;
                }
            })

            return modal.present();
        },
        getStores() {
            userRepository.getStores().then( resp => {
                this.stores = resp.data;
                if ( this.stores.length === 1 ) {
                    this.form.store = this.stores[0];
                }
            })
        },
        checkCanDelivery() {
            if ( this.form.store === null || this.form.clientAddress === null ) return;

            storeRepository.checkStoreOperatesAtLocation( this.form.store.id, this.form.clientAddress.lat, this.form.clientAddress.lng ).then( res => {
                this.deliveryCost = res.data.delivery_charges;
                this.canDeliver = res.data.operates;
            })
        },
        async openStoreSelect() {
            const options = [];

            for(const store of this.stores) {
                options.push({
                    text: store.name,
                    handler: () => {
                        this.form.store = store;
                        this.checkCanDelivery();
                    },
                });
            }

            const actionSheet = await actionSheetController
                .create({
                    header: 'Selecciona el punt de recollida',
                    buttons: options,
                });
            await actionSheet.present();
        },
        dateFormatted() {
            if ( this.form ) {
                return moment(this.form.date).format('dddd D, HH:mm');
            } else {
                return '';
            }
        },
        deliveryCostFormatted() {
            return this.deliveryCost ? this.deliveryCost.replace('.', ',') : null;
        }
    },
    computed: {
        ...mapState(authStore, ['user']),
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonIcon,
        IonTextarea,
        IonItem,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonBackButton,
        IonInput
    }
});
