<template>
    <ion-card>
        <ion-card-header>
            <ion-card-subtitle>{{ order.unique_order_id }}<span class="status" :class="'status-' + order.orderstatus_id">{{ statusLabel }}</span></ion-card-subtitle>

<!--            <ion-card-title>Marc Pagès</ion-card-title>-->
        </ion-card-header>

        <ion-card-content>
            <div class="drive-steps">
                <div class="drive-step" :class="{'current': order.orderstatus_id <= 3}">
                    <span :class="{'done': order.orderstatus_id > 3 && order.orderstatus_id !== 6}">
                        <ion-icon :icon="ellipsisHorizontalOutline" v-if="order.orderstatus_id <= 3 && order.orderstatus_id !== 6"></ion-icon>
                        <ion-icon :icon="checkmarkOutline" v-if="order.orderstatus_id > 3 && order.orderstatus_id !== 6"></ion-icon>
                    </span>
                    {{ order.restaurant.address }} ({{ order.restaurant.name }})
                    <div class="step-line"></div>
                </div>
                <div class="drive-step" :class="{'current': order.orderstatus_id > 3 && order.orderstatus_id !== 6}">
                    <span :class="{'done': order.orderstatus_id >= 5 && order.orderstatus_id !== 6}">
                        <ion-icon :icon="ellipsisHorizontalOutline" v-if="order.orderstatus_id < 5 && order.orderstatus_id !== 6"></ion-icon>
                        <ion-icon :icon="checkmarkOutline" v-if="order.orderstatus_id >= 5 && order.orderstatus_id !== 6"></ion-icon>
                    </span>
                    {{ order.address }}
                </div>
            </div>
            <div class="expected-date" v-if="order.orderstatus_id < 4">Entrega prevista: {{ deliveryDate }}</div>
            <div class="card-actions" v-if="order.orderstatus_id < 3">
                <ion-button size="small" fill="outline" color="medium" @click="openDateSelectModal">Modificar data d'entrega</ion-button>
                <ion-button size="small" fill="outline" color="danger" @click="cancelOrder" v-if="isShopOwner">Cancel·lar</ion-button>
            </div>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonIcon, modalController, alertController, loadingController} from "@ionic/vue";
import { pin, ellipsisHorizontalOutline, checkmarkOutline } from 'ionicons/icons';
import DateSelectModal from "@/views/modals/DateSelectModal.vue";
import moment from "moment";
import {OrdersRepository} from "@/repositories/orders-repository";
import {AlertsUtils} from "@/utils/alerts-utils";
import {mapState} from "pinia";
import {authStore} from "@/stores/auth-store";

const ordersRepo = new OrdersRepository();

export default defineComponent({
    name: 'OrderRow',
    data() {
        return {
            pin,
            ellipsisHorizontalOutline,
            checkmarkOutline,
        }
    },
    props: {
        order: {
            type: Object,
            required: true
        },
    },
    methods: {
        async openDateSelectModal() {
            const modal = await modalController
                .create({
                    component: DateSelectModal,
                    componentProps: {
                        storeId: this.order.restaurant.id
                    },
                })

            modal.onDidDismiss().then( async res => {
                if ( res.data ) {
                    const loading = await loadingController.create({})
                    await loading.present();
                    ordersRepo.changePickupDate( this.order.id, res.data ).finally( () => {
                        loading.dismiss();
                        this.$emit('orderUpdate');
                        AlertsUtils.showSimpleAlert("S'ha canviat la data d'entrega");
                    })
                }
            })

            return modal.present();
        },
        async cancelOrder() {
            const alert = await alertController
                .create({
                    header: "Segur que vols cancel·lar aquest enviament?",
                    buttons: [
                        {
                            text: 'No fer res',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('Confirm Cancel:')
                            },
                        },
                        {
                            text: 'Cancel·lar enviament',
                            cssClass: 'alert-btn-warning',
                            handler: async () => {
                                const loading = await loadingController.create({})
                                await loading.present();
                                ordersRepo.cancelOrder( this.order.id ).finally( () => {
                                    loading.dismiss();
                                    this.$emit('orderUpdate');
                                })
                            },
                        },
                    ],
                });
            await alert.present();
        },
    },
    computed: {
        statusLabel() {
            if ( this.order.orderstatus_id === 1 ) {
                return "Registrada"
            } else if ( this.order.orderstatus_id === 2 ) {
                return "Pendent repartidor"
            } else if ( this.order.orderstatus_id === 3 ) {
                return "En ruta"
            }else if ( this.order.orderstatus_id === 4 ) {
                return "En entrega"
            } else if ( this.order.orderstatus_id === 5 ) {
                return "Entregada"
            } else if ( this.order.orderstatus_id === 6 ) {
                return "Cancel·lada"
            }
            return ''
        },
        deliveryDate(): string {
            return moment(this.order.pickup_date).format('dddd D, HH:mm')
        },
        ...mapState(authStore, ['isShopOwner'])
    },
    components: {
        IonIcon,
        IonCardHeader,
        IonCardContent,
        IonCardSubtitle,
        IonCard
    }
});
</script>

<style scoped>
.status {
    padding: 2px 5px;
    background: #e9e9e9;
    color: #161616;
    border-radius: 4px;
    float: right;
}
.status-2 {
    background: #c7b8ff;
    color: #221065;
}
.status-4, .status-3 {
    background: #b8e7ff;
    color: #104965;
}
.status-5 {
    background: #deffb8;
    color: #276510;
}
.status-6 {
    background: #ffb8b8;
    color: #651010;
}
.drive-step.current {
    color: #354580;
}
.drive-step {
    padding-left: 25px;
    position: relative;
    margin: 5px 0;
}
.drive-step span ion-icon {
    top: 0.5px;
    position: relative;
    font-size: 13px;
}
.drive-step span.done {
    background: #4bb04f;
    color: #e3ffe4;
}
.drive-step span {
    width: 20px;
    height: 20px;
    background: #dadada;
    position: absolute;
    text-align: center;
    border-radius: 100%;
    left: 0;
    z-index: 1;
    top: 1px;
}
.step-line {
    position: absolute;
    width: 2px;
    top: 12px;
    left: 9px;
    bottom: -7px;
    background: #dadada;
}
.card-actions {
    text-align: left;
    top: 10px;
    position: relative;
}
.expected-date {
    font-size: 13px;
    margin-top: 15px;
    margin-left: 2px;
}
</style>
