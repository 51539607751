import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import Login from "@/views/Login.vue";
import {authGuard} from "@/guards/auth-guard";
import NewOrder from "@/views/NewOrder.vue";
import MagicLogin from "@/views/MagicLogin.vue";
import {shopOwnerGuard} from "@/guards/shop-owner-guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    beforeEnter: authGuard
  },
  {
    path: '/new-order',
    name: 'NewOrder',
    component: NewOrder,
    beforeEnter: [ authGuard, shopOwnerGuard]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/l/:key',
    name: 'MagicLogin',
    component: MagicLogin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
