
import {IonContent, IonHeader, IonPage, IonInput, loadingController, alertController, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {authStore} from "@/stores/auth-store";
import {mapActions, mapState} from "pinia";
import {AuthRepository} from "@/repositories/auth-repository";

const authRepo = new AuthRepository();

export default defineComponent({
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
        }
    },
    mounted() {
        console.log(this.user);
    },
    methods: {
        async login() {
            const loading = await loadingController.create({})
            await loading.present()
            authRepo.doLogin(this.email, this.password).then( res => {
                this.setUser(res.data.data);
                this.$router.replace('/');
            }).catch( () => {
                this.showLoginErrorDialog();
            }).finally( () => {
                loading.dismiss()
            })
        },
        async showLoginErrorDialog() {
            const alert = await alertController
                .create({
                    header: "Dades incorrectes",
                    message: "No s'ha pogut iniciar sessió. Comprova que les dades són correctes.",
                    buttons: ["D'acord"],
                });
            return alert.present();
        },
        ...mapActions(authStore, ['setUser']),
    },
    computed: {
        ...mapState(authStore, ['loggedIn','user']),
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonInput,
        IonToolbar
    },
});
