
import {defineComponent} from 'vue';
import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonIcon, modalController, alertController, loadingController} from "@ionic/vue";
import { pin, ellipsisHorizontalOutline, checkmarkOutline } from 'ionicons/icons';
import DateSelectModal from "@/views/modals/DateSelectModal.vue";
import moment from "moment";
import {OrdersRepository} from "@/repositories/orders-repository";
import {AlertsUtils} from "@/utils/alerts-utils";
import {mapState} from "pinia";
import {authStore} from "@/stores/auth-store";

const ordersRepo = new OrdersRepository();

export default defineComponent({
    name: 'OrderRow',
    data() {
        return {
            pin,
            ellipsisHorizontalOutline,
            checkmarkOutline,
        }
    },
    props: {
        order: {
            type: Object,
            required: true
        },
    },
    methods: {
        async openDateSelectModal() {
            const modal = await modalController
                .create({
                    component: DateSelectModal,
                    componentProps: {
                        storeId: this.order.restaurant.id
                    },
                })

            modal.onDidDismiss().then( async res => {
                if ( res.data ) {
                    const loading = await loadingController.create({})
                    await loading.present();
                    ordersRepo.changePickupDate( this.order.id, res.data ).finally( () => {
                        loading.dismiss();
                        this.$emit('orderUpdate');
                        AlertsUtils.showSimpleAlert("S'ha canviat la data d'entrega");
                    })
                }
            })

            return modal.present();
        },
        async cancelOrder() {
            const alert = await alertController
                .create({
                    header: "Segur que vols cancel·lar aquest enviament?",
                    buttons: [
                        {
                            text: 'No fer res',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('Confirm Cancel:')
                            },
                        },
                        {
                            text: 'Cancel·lar enviament',
                            cssClass: 'alert-btn-warning',
                            handler: async () => {
                                const loading = await loadingController.create({})
                                await loading.present();
                                ordersRepo.cancelOrder( this.order.id ).finally( () => {
                                    loading.dismiss();
                                    this.$emit('orderUpdate');
                                })
                            },
                        },
                    ],
                });
            await alert.present();
        },
    },
    computed: {
        statusLabel() {
            if ( this.order.orderstatus_id === 1 ) {
                return "Registrada"
            } else if ( this.order.orderstatus_id === 2 ) {
                return "Pendent repartidor"
            } else if ( this.order.orderstatus_id === 3 ) {
                return "En ruta"
            }else if ( this.order.orderstatus_id === 4 ) {
                return "En entrega"
            } else if ( this.order.orderstatus_id === 5 ) {
                return "Entregada"
            } else if ( this.order.orderstatus_id === 6 ) {
                return "Cancel·lada"
            }
            return ''
        },
        deliveryDate(): string {
            return moment(this.order.pickup_date).format('dddd D, HH:mm')
        },
        ...mapState(authStore, ['isShopOwner'])
    },
    components: {
        IonIcon,
        IonCardHeader,
        IonCardContent,
        IonCardSubtitle,
        IonCard
    }
});
