import {BaseRepository} from "@/repositories/base-repository";
import axios from "axios";

export class StoreRepository extends BaseRepository {

    getStoreInfo(storeId: number) {
        return axios.post( this.getUrl('/get-restaurant-info-by-id/ ' + storeId + '?timeslots=true'))
    }

    checkStoreOperatesAtLocation(storeId: number, latitude: any, longitude: any) {
        return axios.post( this.getUrl('/check-restaurant-operation-service'), {
            /* eslint-disable @typescript-eslint/camelcase */
            restaurant_id: storeId,
            latitude,
            longitude,
        })
    }

}
