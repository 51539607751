<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  }
});
</script>

<style lang="scss">
@import "../public/assets/fonts/omnes/stylesheet.css";

* {
    font-family: OmnesRegular;
}

.container-toolbar {
    position: relative;
    ion-toolbar {
        max-width: 550px;
        margin: 0 auto;
    }
}
.container-desktop {
    max-width: 550px;
    margin: 0 auto;
    padding: 15px;
    position: relative;
}

.bg-main {
    background: #38362E;
    --background: #38362E;
}
ion-title {
    --color: #FFF;
}
ion-button {
    font-weight: 700;
}

.pac-container {
    position: relative !important;
    border: none;
    box-shadow: none;
    .pac-item {
        font-size: 14px;
        padding: 6px 10px;
        .pac-item-query {
            font-size: 15px;
            padding-right: 8px;
        }
    }
}
.pac-logo:after {
    margin-right: 10px;
}
ion-title {
    padding: 0;
}
.swal2-styled.swal2-confirm {
    background-color: #50ab21 !important;
}
.alert-btn-warning {
    color: #cc2020 !important;
}
.clickable {
    cursor: pointer;
}
</style>
