<template>
    <div></div>
</template>

<script lang="ts">
import {loadingController, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {authStore} from "@/stores/auth-store";
import {mapActions, mapState} from "pinia";
import {AuthRepository} from "@/repositories/auth-repository";

const authRepo = new AuthRepository();

export default defineComponent({
    name: 'MagicLogin',
    data() {
        return {
            key: '' as any,
        }
    },
    mounted() {
        this.key = this.$route.params.key;
        this.login();
    },
    methods: {
        async login() {
            const loading = await loadingController.create({})
            await loading.present()
            authRepo.doMagicLogin(this.key).then( res => {
                this.setUser(res.data.data);
                this.$router.replace('/');
            }).catch( err => {
               if ( err.status === 401 ) {
                   this.showLoginErrorDialog();
               }
            }).finally( () => {
                loading.dismiss()
            })
        },
        async showLoginErrorDialog() {
            const alert = await alertController
                .create({
                    header: "No s'ha pogut carregar la pàgina",
                    message: "Aquesta pàgina ha caducat o no existeix.",
                    buttons: ["D'acord"],
                });
            return alert.present();
        },
        ...mapActions(authStore, ['setUser']),
    },
    computed: {
        ...mapState(authStore, ['loggedIn','user']),
    },
    components: {
    },
});
</script>

<style scoped>

</style>
