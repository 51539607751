
import {
    IonContent,
    IonHeader,
    IonButton,
    IonTitle,
    IonIcon,
    IonPage,
    loadingController,
    IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    alertController,
    IonRefresher,
    IonRefresherContent,
    IonSelect,
    IonSelectOption,
    popoverController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import OrderRow from "@/views/includes/OrderRow.vue";
import {OrdersRepository} from "@/repositories/orders-repository";
import { add, personOutline } from 'ionicons/icons';
import moment from "moment";
import UserPopover from "@/views/popovers/UserPopover.vue";
import {mapState} from "pinia";
import {authStore} from "@/stores/auth-store";

const ordersRepo = new OrdersRepository();

export default defineComponent({
    name: 'Home',
    data() {
        return {
            add,
            personOutline,
            loaded: false,
            orders: [] as any[],
            page: 1
        }
    },
    mounted() {
        this.getOrders();
        moment.locale('ca');
        this.$emitter.on('refreshHome', () => {
            this.reset();
        })
    },
    methods: {
        nextPage(ev?: CustomEvent) {
            this.page++;
            this.getOrders(ev);
        },
        reset(ev?: CustomEvent) {
            this.page = 1;
            this.getOrders(ev);
        },
        async getOrders(ev?: CustomEvent) {
            this.loaded = false;
            const loading = await loadingController.create({})
            if ( this.page === 1 ) {
                this.orders = [];
                await loading.present()
            }
            ordersRepo.getMyOrders( this.page ).then( resp => {
                for (const order of resp.data) {
                    this.orders.push(order);
                }

                if ( resp.data.length < 15 ) {
                    if ( ev ) {
                        // @ts-ignore
                        ev.target.disabled = true;
                    }
                }
            }).finally( () => {
                loading.dismiss();

                if ( ev ) {
                    // @ts-ignore
                    ev.target.complete();
                }

                this.loaded = true;
            })
        },
        async openUserPopover(ev: Event) {
            const popover = await popoverController
                .create({
                    component: UserPopover,
                    event: ev,
                    translucent: true
                })
            await popover.present();
        },
    },
    computed: {
        ...mapState(authStore, ['isShopOwner'])
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        OrderRow,
        IonButton,
        IonList,
        IonTitle,
        IonIcon,
        IonInfiniteScroll,
        IonRefresher,
        IonRefresherContent,
        IonInfiniteScrollContent,
    }
});
