<template>
    <ion-page>
        <ion-header>
            <div class="container-toolbar bg-main">
                <ion-toolbar class="bg-main">
                    <ion-buttons slot="start">
                        <ion-button @click="closeModal"><ion-icon :icon="close"></ion-icon></ion-button>
                    </ion-buttons>
                    <ion-title>Pagament</ion-title>
                </ion-toolbar>
            </div>
        </ion-header>

        <ion-content>
            <iframe v-if="src" :src="src"></iframe>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar, alertController, loadingController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {modalController} from "@ionic/vue";
import {close} from "ionicons/icons";

export default defineComponent({
    name: 'PaymentModal',
    data() {
        return {
            close,
            src: null as any
        }
    },
    mounted() {
        this.loadIframe();
        this.setupPaymentEventWathcer();
    },
    beforeUnmount() {
        this.removePaymentEventWathcer();
    },
    methods: {
        loadIframe() {
            this.src = process.env.VUE_APP_WEBSITE_URL + '/payment/' + this.flowId + '?skip-current-check=true'
        },
        handlePaymentEvent(e: any) {
            const data = e.data;
            if ( data.source === 'payment' && data.status === true ) {
                modalController.dismiss('placeOrder');
            } else if ( data.source === 'payment' && data.status === false ) {
                modalController.dismiss(data.message);
            }
        },
        setupPaymentEventWathcer() {
            // @ts-ignore
            const eventMethod: any = window.addEventListener ? "addEventListener" : "attachEvent";
            const eventer = window[eventMethod];
            const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

            // @ts-ignore
            eventer(messageEvent, this.handlePaymentEvent, false);
        },
        removePaymentEventWathcer() {
            // @ts-ignore
            const eventMethod: any = window.addEventListener ? "removeEventListener" : "detachEvent";
            const eventer = window[eventMethod];
            const messageEvent = eventMethod == "detachEvent" ? "onmessage" : "message";

            // @ts-ignore
            eventer(messageEvent, this.__checkForEvents, false);
        },
        async closeModal() {
            const alert = await alertController
                .create({
                    header: "Segur que vols cancel·lar el pagament?",
                    message: "La comanda no es crearà",
                    buttons: [
                        {
                            text: 'Continuar amb el pagament',
                            role: 'cancel',
                            cssClass: 'secondary'
                        },
                        {
                            text: 'Cancel·lar pagament i tornar',
                            cssClass: 'alert-btn-warning',
                            handler: async () => {
                                modalController.dismiss();
                            },
                        },
                    ],
                });
            await alert.present();
        },
    },
    props: {
        flowId: {
            type: String,
            required: true
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
    },
});
</script>

<style lang="scss" scoped>
iframe {
    border: 0;
    width: 100%;
    height: 100%;
}
</style>
