import {BaseRepository} from "@/repositories/base-repository";
import axios from "axios";

export class AuthRepository extends BaseRepository {

    doLogin(email: string, password: string) {
        return axios.post( this.getUrl('/login'), {
            email,
            password
        })
    }

    doMagicLogin(key: string) {
        return axios.get( this.getUrl('/login/magic/' + key) )
    }

}
