<template>
    <ion-page>
        <ion-header>
            <div class="container-toolbar bg-main">
                <ion-toolbar class="bg-main">
                    <ion-buttons slot="start">
                        <ion-button @click="closeModal"><ion-icon :icon="close"></ion-icon></ion-button>
                    </ion-buttons>
                    <ion-title>Selecciona el dia i hora d'entrega</ion-title>
                </ion-toolbar>
            </div>
        </ion-header>

        <ion-content>
            <div class="time-slots-container">
                <div class="time-slots-days">
                    <div class="day-slot-title" :class="{'selected': day === selectedDay}" @click="setDay(day)"
                         v-for="(timeslots, day) in timeslots" :key="day">
                        {{ dateDayNum(day) }}
                        <span>{{ dateDayName(day) }}</span>
                    </div>
                </div>
                <div class="time-slots" v-if="selectedDay">
                    <div v-for="(hour, index) of timeslots[selectedDay]" :key="index" class="time-slot-option"
                         @click="selectDate(hour)">
                        {{ hour }}
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonPage,
    IonToolbar, IonButtons, IonButton, IonIcon,
    loadingController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {modalController} from "@ionic/vue";
import {StoreRepository} from "@/repositories/store-repository";
import moment from 'moment'
import { close } from 'ionicons/icons';

const storeRepository = new StoreRepository();

export default defineComponent({
    name: 'DateSelectModal',
    data() {
        return {
            close,
            timeslots: [] as any[],
            selectedDay: null as any,
            selectedDate: null as any
        }
    },
    async mounted() {
        moment.locale('ca');
        this.getStoreTimeslots();
    },
    methods: {
        async getStoreTimeslots() {
            const loading = await loadingController.create({})
            await loading.present();
            storeRepository.getStoreInfo(this.storeId).then(resp => {
                this.timeslots = resp.data.timeslots;
                if (Object.keys(this.timeslots).length > 0) {
                    this.setDay(Object.keys(this.timeslots)[0]);
                }
            }).finally( () => {
                loading.dismiss();
            })
        },
        setDay(day: string) {
            this.selectedDay = day;
        },
        selectDate(time: any) {
            modalController.dismiss(this.selectedDay + ' ' + time);
        },
        closeModal() {
            modalController.dismiss();
        },
        dateDayName(date: string) {
            return moment(date).format('dd');
        },
        dateDayNum(date: string) {
            return moment(date).format('D');
        }
    },
    props: {
        storeId: {
            type: Number,
            required: true
        },
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonIcon,
        IonButton,
    },
});
</script>

<style lang="scss" scoped>
.time-slot-option.checked {
    background: #d5ffc82e;
    color: #5fb446;
    border-color: #5fb446;
    font-weight: bold;
}

.time-slot-option {
    border: 1px solid #d0d0d0;
    padding: 7px;
    font-size: 20px;
    border-radius: 3px;
    flex: 1;
    min-width: 30%;
    max-width: 30%;
    margin: 4px 4px;
    text-align: center;
}

.day-slot-title.selected {
    border: 1px solid #60b346;
}

.day-slot-title.selected span {
    background: #60b346;
    color: white;
}

.day-slot-title span {
    display: block;
    width: fit-content;
    margin: 0 auto;
    background: white;
    padding: 1px 6px;
    line-height: 17px;
    border-radius: 3px;
    color: #a2a2a2;
}

.day-slot-title {
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    text-align: center;
    width: 68px;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    padding: 8px;
    margin-right: 8px;
}

.time-slots-days {
    overflow-x: scroll;
    white-space: nowrap;
    margin-left: -36px;
    margin-right: -36px;
    padding: 20px 20px 10px 40px;
    -webkit-overflow-scrolling: touch;
}

.time-slots {
    justify-content: left;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.time-slots-container {
    margin: 20px;
}

.sel-data-btn {
    background: white;
    border: 0;
    box-shadow: 1px 1px 3px #b7b7b7;
    border-radius: 8px;
    font-size: 17px;
    padding: 1px 16px;
    outline: none;
}
</style>
