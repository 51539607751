import {alertController} from "@ionic/vue";


export class AlertsUtils {

    public static async showSimpleAlert(message: string) {
        const alert = await alertController
            .create({
                header: message,
                buttons: ["D'acord"],
            });
        await alert.present();
    }

}
