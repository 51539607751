<template>
    <ion-page>
        <ion-header>
            <div class="container-toolbar bg-main">
                <ion-toolbar class="bg-main">
                        <ion-buttons slot="start">
                            <ion-button @click="openUserPopover">
                                <ion-icon :icon="personOutline"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                        <ion-title>Els meus enviaments</ion-title>
                        <ion-buttons slot="end" v-if="isShopOwner">
                            <ion-button router-link="/new-order"><ion-icon :icon="add"></ion-icon></ion-button>
                        </ion-buttons>
                </ion-toolbar>
            </div>
        </ion-header>

        <ion-content :fullscreen="true">
            <ion-refresher slot="fixed" @ionRefresh="reset($event)">
                <ion-refresher-content></ion-refresher-content>
            </ion-refresher>

            <div class="container container-desktop">

                <div class="empty" v-if="loaded && orders.length === 0">
                    Encara no has fet cap enviament
                    <img src="assets/images/empty-orders.svg" />
                    <ion-button router-link="/new-order" v-if="isShopOwner">Crear nou enviament</ion-button>
                </div>

                <ion-list>
                    <OrderRow v-for="order of orders" :key="order.id" :order="order" @orderUpdate="reset"></OrderRow>
                </ion-list>

                <ion-infinite-scroll
                    @ionInfinite="nextPage($event)"
                    threshold="100px"
                    id="infinite-scroll"
                >
                    <ion-infinite-scroll-content
                        loading-spinner="bubbles"
                        loading-text="">
                    </ion-infinite-scroll-content>
                </ion-infinite-scroll>

            </div>

        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonContent,
    IonHeader,
    IonButton,
    IonTitle,
    IonIcon,
    IonPage,
    loadingController,
    IonToolbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    alertController,
    IonRefresher,
    IonRefresherContent,
    IonSelect,
    IonSelectOption,
    popoverController
} from '@ionic/vue';
import {defineComponent} from 'vue';
import OrderRow from "@/views/includes/OrderRow.vue";
import {OrdersRepository} from "@/repositories/orders-repository";
import { add, personOutline } from 'ionicons/icons';
import moment from "moment";
import UserPopover from "@/views/popovers/UserPopover.vue";
import {mapState} from "pinia";
import {authStore} from "@/stores/auth-store";

const ordersRepo = new OrdersRepository();

export default defineComponent({
    name: 'Home',
    data() {
        return {
            add,
            personOutline,
            loaded: false,
            orders: [] as any[],
            page: 1
        }
    },
    mounted() {
        this.getOrders();
        moment.locale('ca');
        this.$emitter.on('refreshHome', () => {
            this.reset();
        })
    },
    methods: {
        nextPage(ev?: CustomEvent) {
            this.page++;
            this.getOrders(ev);
        },
        reset(ev?: CustomEvent) {
            this.page = 1;
            this.getOrders(ev);
        },
        async getOrders(ev?: CustomEvent) {
            this.loaded = false;
            const loading = await loadingController.create({})
            if ( this.page === 1 ) {
                this.orders = [];
                await loading.present()
            }
            ordersRepo.getMyOrders( this.page ).then( resp => {
                for (const order of resp.data) {
                    this.orders.push(order);
                }

                if ( resp.data.length < 15 ) {
                    if ( ev ) {
                        // @ts-ignore
                        ev.target.disabled = true;
                    }
                }
            }).finally( () => {
                loading.dismiss();

                if ( ev ) {
                    // @ts-ignore
                    ev.target.complete();
                }

                this.loaded = true;
            })
        },
        async openUserPopover(ev: Event) {
            const popover = await popoverController
                .create({
                    component: UserPopover,
                    event: ev,
                    translucent: true
                })
            await popover.present();
        },
    },
    computed: {
        ...mapState(authStore, ['isShopOwner'])
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        OrderRow,
        IonButton,
        IonList,
        IonTitle,
        IonIcon,
        IonInfiniteScroll,
        IonRefresher,
        IonRefresherContent,
        IonInfiniteScrollContent,
    }
});
</script>

<style lang="scss" scoped>
#container {
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;

    color: #8c8c8c;

    margin: 0;
}

#container a {
    text-decoration: none;
}
.bg-main {
    background: #38362E;
    --background: #38362E;
}
ion-title {
    --color: #FFF;
}
.logo {
    width: 300px;
}

.empty {
    text-align: center;
    color: gray;
    font-size: 20px;
    margin: 30px 0;
    img {
        padding: 30px 0;
    }
}
</style>
