import {authStore} from "@/stores/auth-store";

export function authGuard(to: any, from: any, next: any)
{
    if ( authStore().loggedIn ) {
        next();
    } else {
        next({name: 'Login'});
    }
}
