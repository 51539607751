import {authStore} from "@/stores/auth-store";

export function shopOwnerGuard(to: any, from: any, next: any)
{
    if ( authStore().isShopOwner ) {
        next();
    } else {
        next({name: 'Home'});
    }
}
