<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="bg-main">
                <div class="container-desktop">
                    <img class="logo" src="assets/images/logo.png" />
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">

            <div class="container container-desktop">
                <form>
                    <h2 class="login-title">Entrada d'usuaris</h2>
                    <ion-list lines="full" class="ion-no-margin ion-no-padding">
                        <ion-item>
                            <ion-label position="stacked">Email</ion-label>
                            <ion-input v-model="email" required type="text"></ion-input>
                        </ion-item>

                        <ion-item>
                            <ion-label position="stacked">Contrasenya</ion-label>
                            <ion-input v-model="password" required type="password"></ion-input>
                        </ion-item>

                        <ion-button @click="login" expand="full">Entrar</ion-button>

                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {IonContent, IonHeader, IonPage, IonInput, loadingController, alertController, IonToolbar} from '@ionic/vue';
import {defineComponent} from 'vue';
import {authStore} from "@/stores/auth-store";
import {mapActions, mapState} from "pinia";
import {AuthRepository} from "@/repositories/auth-repository";

const authRepo = new AuthRepository();

export default defineComponent({
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
        }
    },
    mounted() {
        console.log(this.user);
    },
    methods: {
        async login() {
            const loading = await loadingController.create({})
            await loading.present()
            authRepo.doLogin(this.email, this.password).then( res => {
                this.setUser(res.data.data);
                this.$router.replace('/');
            }).catch( () => {
                this.showLoginErrorDialog();
            }).finally( () => {
                loading.dismiss()
            })
        },
        async showLoginErrorDialog() {
            const alert = await alertController
                .create({
                    header: "Dades incorrectes",
                    message: "No s'ha pogut iniciar sessió. Comprova que les dades són correctes.",
                    buttons: ["D'acord"],
                });
            return alert.present();
        },
        ...mapActions(authStore, ['setUser']),
    },
    computed: {
        ...mapState(authStore, ['loggedIn','user']),
    },
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonInput,
        IonToolbar
    },
});
</script>

<style scoped>
.bg-main {
    --background: #38362E;
}
.logo {
    width: 300px;
}
.login-title {
    text-align: center;
    margin-bottom: 30px;
}
</style>
