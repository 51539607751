import {BaseRepository} from "@/repositories/base-repository";
import axios from "axios";

export class UserRepository extends BaseRepository {

    getStores() {
        return axios.get( this.getUrl('/stores') )
    }

}
