import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const authStore = defineStore({

    id: 'authStore',

    state() {
        return {
            user: useStorage('user', {})
        }
    },

    getters: {
        loggedIn(): boolean {
            return this.user !== null && Object.prototype.hasOwnProperty.call(this.user, 'id');
        },
        isShopOwner(): boolean {
            return this.user !== null && this.user.roles.includes('Shop Owner');
        },
        token() {
            if ( this.loggedIn ) {
                return this.user.auth_token;
            }
            return null;
        },
    },

    actions: {
        setUser(user: any) {
            this.user = user;
        },
        logout() {
            this.user = null;
        }
    },

})
