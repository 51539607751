import {BaseRepository} from "@/repositories/base-repository";
import axios from "axios";

export class OrdersRepository extends BaseRepository {

    getMyOrders(page: number) {
        return axios.post( this.getUrl('/get-orders'), {
            page
        })
    }

    placeOrder(description: string, storeId: number, pickupDate: string, clientAddress: any, phone: any, uniqid: string) {
        return axios.post( this.getUrl('/place-order'), {
            "order": [
                {
                    "id": -1,
                    "quantity": 1,
                    "name": description,
                    "price": 0,
                    "restaurant_id": storeId
                }
            ],
            "pickup_date": pickupDate,
            "delivery_scheduled": 1,
            "user": {
                "data": {
                    "id": 1,
                    "phone": phone,
                    "default_address": clientAddress
                }
            },
            "location": clientAddress,
            "delivery_type": 1,
            "method": "REDSYS",
            "order_comment": description,
            "uniqid": uniqid
        })
    }

    cancelOrder(orderId: number) {
        return axios.post( this.getUrl('/cancel-order'), {
            "order_id": orderId
        })
    }

    changePickupDate(orderId: number, date: string) {
        return axios.post( this.getUrl(`/order/${orderId}/change-pickup-date`), {
            date
        })
    }

}
