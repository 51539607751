import {BaseRepository} from "@/repositories/base-repository";
import axios from "axios";

export class AddressRepository extends BaseRepository {

    createAddress(userId: any, latitude: any, longitude: any, address: string) {
        return axios.post( this.getUrl('/save-address'), {
            /* eslint-disable @typescript-eslint/camelcase */
            get_only_default_address: true,
            /* eslint-disable @typescript-eslint/camelcase */
            user_id: userId,
            latitude,
            longitude,
            address,
            house: address
        })
    }

}
